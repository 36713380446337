import { Grid, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import comm from '../comm/comm';
import DDL from '../controls/form/DDL';
import IPGrid from '../controls/grid/IPGrid';
import JournalHeader from '../controls/grid/JournalHeader';
import JournalRow from '../controls/grid/JournalRow';
const Journal = (props) => {
    let [user, setUser] = useState("0");
    let [users, setUsers] = useState([]);
    let [searchParams, ] = useState({
        func: "/backend/sis/secure/history/search",
        export:{
            xlsx: "/backend/sis/secure/history/search/xlsx"
        },
        params: {}
    });
    useEffect(() => {
        comm.Instance().get("backend/sis/secure/user/top/active/10000")
            .then(
                r => {
                    const _users = [{ reference: "0", name: "" }].concat(r.data.rows);
                    setUsers(_users);
                })
    }, []);

    useEffect(() => {
        console.log(searchParams);
        //searchParams.params.createdBy = user === "0" ? null : user;
        //setSearchParams(JSON.parse(JSON.stringify(searchParams)));
    }, [user, searchParams]);
    
    return (

        <div className="container">
            <h2 className="appeal-title mb-5">Журнал</h2>
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DDL label="Потребител" value={user} change={e => setUser(e)}
                            items={users}
                            idfield="reference"
                            labelfield="name"></DDL>
                    </Grid>

                </Grid>
            </Paper>
            <Paper>
                <IPGrid searchParams={searchParams}
                    header={() => <JournalHeader></JournalHeader>}
                    row={(item) => <JournalRow item={item}></JournalRow>}>
                </IPGrid>
            </Paper>
        </div>
    )
}

export default Journal;